
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getOneLetterWeekDays, getUniversalDate, getUniversalISOStringDate } from '@/utils';
import { UserClient } from '@/services/Services';
import * as OM from "@/model";

@Options({})
export default class Calendar extends Vue {

    @Prop({
        default: () => new Date()
    }) value: Date;

    week: any[] = [];
    monthDays: Date[] = [];
    today = new Date();
    events: OM.EventCalendarVM[] = [];
    date: Date = new Date();

    created() {
        this.week = getOneLetterWeekDays();
        this.loadReservation();
    }

    returnWeekClass(day){
        if(day.key == "0" || day.key == "6")
            return "red"
        
        return "";
    }

    @Watch("value")
    loadReservation(){
        this.events = [];
        this.date = getUniversalISOStringDate(this.value);
        UserClient.getMyEventsByMonth(<any>this.date)
        .then( x => {
            var temp = x;

            temp.forEach( x => {
                x.date = <any>getUniversalDate(x.date);
                this.events.push(x);
            })

            this.changeCalendar();
        })
    }

    changeCalendar(){
        this.monthDays = [];
        var tempDate = new Date(this.value);

        tempDate = new Date(tempDate.setDate(1));

        while(tempDate.getDay() != this.week[0].key)
            tempDate = new Date(tempDate.setDate(tempDate.getDate() - 1));

        var lastDay = new Date(tempDate);
        lastDay = new Date(lastDay.setDate(lastDay.getDate() + 42))

        while(tempDate.getMonth() != lastDay.getMonth() || tempDate.getDate() != lastDay.getDate()){
            this.monthDays.push(new Date(tempDate));
            tempDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
        }
    }

    returnDayClass(day: Date){
        if(day.getFullYear() == this.today.getFullYear() && day.getMonth() == this.today.getMonth() 
            && day.getDate() == this.today.getDate())
            return "today";

        var ris = "";

        if(day.getFullYear() == this.value.getFullYear() && day.getMonth() == this.value.getMonth())
            ris += "month ";
        
        if(day.getDay() == 0 || day.getDay() == 6)
            ris += "red "

        if(this.returnReservations(day) > 0)
            ris += "active"

        return ris;
    }

    returnReservations(day: Date){
        return this.events.filter( x => {
            var dateVm = new Date(x.date);
            return dateVm.getFullYear() == day.getFullYear() && dateVm.getMonth() == day.getMonth() && dateVm.getDate() == day.getDate();
        }).length
    }

    dayClicked(day){
        this.$emit("dayClicked", day);
    }
}
