
import { Options, Vue } from 'vue-class-component';
import { getUniversalDate, getUniversalISOStringDate } from '@/utils';
import Calendar from '../components/calendar.vue';
import store from '@/store';

@Options({
    components: {
        Calendar
    }
})
export default class UserCalendar extends Vue {

    selectedMonth: Date = new Date();

    created() {
        if(this.$route.params.day)
            this.selectedMonth = getUniversalDate(this.$route.params.day);
    }

    changeMonth(prev: boolean) {
        if(prev)
            this.selectedMonth = new Date(this.selectedMonth.setMonth(this.selectedMonth.getMonth() - 1));
        else
            this.selectedMonth = new Date(this.selectedMonth.setMonth(this.selectedMonth.getMonth() + 1));
        
        // store.state.reservationsDate = this.selectedMonth;
    }

    dayClicked(day: Date) {
        this.$router.replace("/usercalendar/" + getUniversalISOStringDate(day));
        this.$router.push("/usercalendar/day/" + getUniversalISOStringDate(day));
    }

}
